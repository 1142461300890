import React, { Component, createRef, memo } from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import ReactFullpage from '@fullpage/react-fullpage';
import BackgroundImage from 'gatsby-background-image';
import { isOffline } from '@utils';

import { 
  CollectionPremiereHero,
  DefaultMainMenu,
  ProgressMenu,
  ScrollIndicator
} from '@components';

import { cubicBezier, MQ } from '../assets/styles/constants';

const CategoryProducts = memo(products => (
  <StyledCategoryProducts>
    {products.products.map(product => (
      <CategoryProduct
        key={`categoryProduct-${product.node.slug}`}
        to={`/collection/${product.node.parent_element.slug}/${product.node.slug}`}
      >
        <CategoryProductTitle dangerouslySetInnerHTML={{ __html: product.node.title }} />
      </CategoryProduct>
    ))}
  </StyledCategoryProducts>
))

const CategorySection = ({ products, category, ...rest }) => {
  const image = get(category, 'node.acf.background_image.localFile.childImageSharp.fluid',  null);

  const categoryProducts = products.edges.filter(
    product => get(product, 'node.parent_element.id') === get(category, 'node.id'),
  );

  return (
    <Section className="section" {...rest}>
      <CollectionSection>
        <ImageBackground>
          {image && (
            <BackgroundImage
              critical={isOffline}
              fluid={image}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          )}
        </ImageBackground>
        <Content>
          <Headline dangerouslySetInnerHTML={{ __html: category.node.title }} />
          {categoryProducts.length > 0 && (
            <CategoryProducts products={categoryProducts} />
          )}
        </Content>
      </CollectionSection>
    </Section>
  )
};

class Collection extends Component {
  constructor(props) {
    super(props);
    this.reactCollectionFullPageRef = createRef(null);
  }

  state = {
    activePage: 1,
    showScrollIndicator: true,
  }

  componentWillUnmount() {
    const fullPageApi = get(this.reactCollectionFullPageRef, 'current.fullpageApi');

    if (fullPageApi) {
      fullPageApi.destroy('all');
    }
  }

  handleReactFullPageAfterLoad = ({ origin, destination, direction }) => {
    this.setState({
      activePage: destination.index + 1,
      showScrollIndicator: false,
    })
  }

  getSections = () => {
    const hero = this.props.data.wordpressPage.acf;
    const categories = this.props.data.categories;

    // const sections = categories.edges;
    const sections = [{
      hero: true,
      title: get(hero, 'cp_hero_title'),
      subtitle: get(hero, 'cp_hero_subtitle'),
      videoPath: get(hero, 'cp_hero_video_path'),
      isActive: true,
    }].concat(categories.edges);

    return sections;
  }

  renderSections = ({ state, fullpageApi }) => {
    const products = this.props.data.products;
    const sections = this.getSections();
    const activeSectionIndex = fullpageApi ? fullpageApi.getActiveSection().index : null;

    return (
      <ReactFullpage.Wrapper>
        {sections.map(({ hero, ...section}, index) => {
          const isActive = index + 1 === this.state.activePage;

          // Prepend hero section
          if (hero) {
            return <CollectionPremiereHero {...section} key={index} isActive={isActive} />;
          }

          return (
            <CategorySection
              key={index}
              category={section}
              products={products}
            />
          );
        })}
      </ReactFullpage.Wrapper>
    )
  }

  render() {
    const itemsForProgressMenu = this.getSections().length;

    return (
      <React.Fragment>
        <DefaultMainMenu />
        <ProgressMenu
          selected={this.state.activePage}
          items={itemsForProgressMenu}
          onItemClick={(number) => {
            this.reactCollectionFullPageRef.current.fullpageApi.moveTo(number)
          }}
        />
        <ReactFullpage
          lazyLoading
          ref={this.reactCollectionFullPageRef}
          licenseKey="78B68DD0-5E89429F-83882F6A-5CF1BF97"
          onLeave={(origin, destination, direction) => {
            this.handleReactFullPageAfterLoad({ origin, destination, direction })
          }}
          render={this.renderSections}
        />
        <ScrollIndicator
          label="Explore collections"
          showScrollIndicator={this.state.showScrollIndicator}
        />
      </React.Fragment>
    )
  }
}

export default Collection

export const pageQuery = graphql`
  query {
    wordpressPage(template: { eq: "page-frontpage.php" }) {
      acf {
        cp_hero_title
        cp_hero_subtitle
        cp_hero_video_path
      }
    }
    categories: allWordpressPage(
      filter: { template: { eq: "page-category-frontpage.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          menu_order
          acf {
            background_image {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    products: allWordpressPage(
      filter: { template: { eq: "page-category-product.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          menu_order
          parent_element {
            id
            slug
          }
        }
      }
    }
  }
`

const CollectionSection = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`



const Section = styled.div``

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;

  .cplogo {
    transform: scale(0.8);
    @media screen and (${MQ.lg}) {
      transform: scale(1);
    }
  }
`

const Headline = styled.span`
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const ImageBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`

const StyledCategoryProducts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  padding-left: 12vw;
  padding-right: 12vw;
`

const CategoryProduct = styled(Link)`
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: 0.6rem;
  line-height: 1.4;
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0.25);

  &:hover, &:focus {
    background-color: #FFF;
    color: #000;
  }

  @media screen and (min-width: 1000px) {
    flex: 33.3333%;
  }
}
`

const CategoryProductTitle = styled.span`
  display: block;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 4px;
`
